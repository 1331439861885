// App.js

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, useLocation } from 'react-router-dom';
import FormGenerator from './components/FormGenerator';
import formConfigurations from './config/formConfigurations.json';
import './App.css';
import Header from './Header';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie'; // Import js-cookie to handle cookies

function App() {
    const [formKey, setFormKey] = useState(null);
    const [portalVisibility, setPortalVisibility] = useState(null);
    const [applicationDoc, setAppDoc] = useState(null);
    const [sharedFolder, setSharedFolderLink] = useState(null);
    const [token, setToken] = useState(null); // State to store the token
    const [decodedToken, setDecodedToken] = useState(null); // State to store the decoded token
    const location = useLocation();

    // Environment variables for URLs
    const API_BASE_URL = process.env.REACT_APP_BACKEND_API_URL;
    const AUTH_URL = process.env.REACT_APP_AUTH_BASE_URL;

    // console.log(AUTH_URL);

    const idmap = {
         // live portals
        "Super Visa": "superVisa",
        "HOME CHILD CARE": "HomeChildCare",
        "Home Support Worker": "HomeSupportWorker",
        "Spousal WP Outside": "SOWPSpouseOutside",
        "Spousal WP Inside": "SpousalApplicationInsideCanada",
        "SOWP- SPOUSE ON STUDY PERMIT": "WorkPermitSpouseOnStudyPermit",
        "Minor Study Permit": "childstudywp",
        "CHILD WP - PARENT ON WP": "ChildOutsideCanada",
        "Vulnerable WP": "VulnerableWP",
        "Parents/Grand Parents Sponsorship": "ParentSponsorshipApplication",
        "Common Law Work Permit": "CommonLawWorkPermit",
        "Spousal Sponsorship Outside": "SpousalSponsorshipOutside",
        "Spousal Sponsorship Inside": "SpousalSponsorshipInside",
        "Child Sponsorship": "ChildSponsorship",
        "Common Law Sponsorship": "CommonLawSponsorship",
        "Home Child Care-direct PR Category":"HomeChildCaredpc",
        "Home Support Worker-direct PR category":"HomeSupportWorkerdpc",

        // UAT portals 
        "PGWP": "Pgwp",
        "LMIA WP Outside": "WorkPermitApplication",
        "LMIA WP Inside": "WorkPermitDocumentChecklist",
        "H & C":"customisedHandC",
        "Port of Entry":"portOfEntryDocuments",
        "TRV":"Trv",
        "Visitor Business":"BusinessVisitVisa",
        "Visitor Tourism":"TourismVisaChecklist",
        "Visitor Other":"VisitorVisaChecklist",
        "Super Visa Extension":"SuperVisaExtensionChecklist",
        "SPOUSE VISIT VISA":"SpousalVisitVisaChecklist",
        "PNP":"PNPGeneralChecklist",
        "SINP":"SINPGeneralChecklist",
        "PNP Federal":"PNPFederalChecklist",
        "Manitoba PNP":"MANITOBAPNPChecklist",
        "Express Entry Profile Creation":"ExpressEntryProfileCreationChecklist",
        "Bridging Open WP":"BridgingOpenWorkPermitChecklist",
        "BC PNP":"BCPNPSkilledWorkerChecklist",
        "AINP SW":"AINPSkilledWorkerChecklist",
        "AAIP SW":"AINPSkilledWorkerChecklist",
        "AINP PGWP":"AINPPostGraduateWorkerChecklist",
        "AAIP PGWP":"AINPPostGraduateWorkerChecklist",
        "Visitor Extension Relatives/Others":"VisitorExtensionRelativesChecklist",
        "Visitor Parents":"VisitorVisaForParentsChecklist",
        "TRP":"TRPDocumentsChecklist",
        "PRTD":"PRTDDocumentsChecklist",
        "CITIZENSHIP MINOR":"CitizenshipMinorChecklist",
        "CITIZENSHIP ADULT":"CitizenshipAdultChecklist",
        "PR Card":"PRCardChecklist",
        "Express Entry":"ExpressEntryChecklist",
        "PR Card Renewal":"PRCardChecklist",
        "Public College Admission":"publicCollegeAdmissionChecklist",
        "Private College Admission":"publicCollegeAdmissionChecklist",
        "Study Permit Inside": "studyPermitChecklist",
        "Study Permit Outside": "studyPermitChecklist",
        "Study permit extension": "studyPermitExtension",
        "Visitor Extension": "visitVisaExtension",
        "Visitor Extension Parents": "visitorVisaExtensionParents",
        "Eligibility Check": "Eligibility Check"
    };

    useEffect(() => {
        // Get the token from cookies
        const token = Cookies.get('token');
        // console.log("Token from cookies:", token);
        if (!token) {
            alert("Authentication required. Redirecting to login...");
            window.location.href = AUTH_URL; // Use environment variable
            return;
        }
        try {
            const decoded = jwtDecode(token);
            // console.log('Decoded JWT:', decoded);
            setToken(token);
            setDecodedToken(decoded);
            // Proceed to fetch the form key
            fetchFormKey(token, decoded);
        } catch (error) {
            console.error('Invalid token', error);
            alert("Invalid authentication. Please try again");
            // Remove the invalid token
            Cookies.remove('token');
            window.location.href = AUTH_URL; // Use environment variable
        }
    }, [location]);

    const fetchFormKey = async (token,decodedToken) => {
        const response = await getRecord();
        if (response && response.records && response.records.length > 0) {
            const typeOfApplication = response.records[0].Type_of_Application?.values || 'N/A';
            const portalVisibility = response.records[0].Portal_Visibility?.values || 'N/A';
            const applicationDoc = response.records[0].Selected_Applicant_s_Documents?.values || 'N/A';
            const sharedFolder = response.records[0].External_Client_Folder?.values || 'N/A';
            try {
                const ownerEmail = response.records[0].Email?.values || 'N/A';
                // console.log('EMAIL TO verify:: ', ownerEmail, decodedToken);
                if (ownerEmail !== decodedToken.user_id) {
                    // Not authenticated
                    alert('Cannot Validate Identity');
                    // Remove the token
                    Cookies.remove('token');
                    window.location.href = AUTH_URL; // Use environment variable
                    return;
                }
            } catch (error) {
                // console.log('Error from authentication', error);
                alert('Something went wrong. Please try again.');
                Cookies.remove('token');
                window.location.href = AUTH_URL; // Use environment variable
                return;
            }
            const data = {
                key: idmap[typeOfApplication],
            };
            // console.log('Fetched Data:', data);
            setPortalVisibility(portalVisibility);
            setFormKey(data.key); // Assuming API response contains { key: "superVisa" }
            setAppDoc(response.records[0]);
            setSharedFolderLink(sharedFolder);
        } else {
            console.warn('Failed to fetch form key.');
        }
    };

    const readStream = async (stream) => {
        const reader = stream.getReader();
        const decoder = new TextDecoder();
        let result;
        let data = '';
        try {
            while (!(result = await reader.read()).done) {
                data += decoder.decode(result.value, { stream: true });
            }
            data += decoder.decode(); // Decode any remaining bytes
            return JSON.parse(data);
        } catch (error) {
            console.error('Error reading and parsing stream as JSON:', error);
            throw error;
        } finally {
            reader.releaseLock();
        }
    }


    const getRecord = async () => {
        const query = new URLSearchParams(location.search);
        const portal_id = query.get('portal');
        const portal_item_id = query.get('portalItem');

        // console.log('Portal ID:', portal_id);
        // console.log('Portal Item ID:', portal_item_id);

        const randomCode = generateRandomCode(40);
        // console.log('Generated Random Code:', randomCode);

        if (portal_item_id) {
            // Update related record status
            // [{ id: rel_rec_id, fields: [{ apiName: "Status", value: status, type: "Choice" }] }];
            const update_list = [
                {
                    id: portal_item_id,
                    fields: [{ apiName: "Status", value: 'Pending Review', type: "Choice" }] 
                },
            ];
            await updateRecord(update_list, 'CC_Portal_Items');
        }

        const requestBody = {
            api_name: 'getRecord',
            batch_id: randomCode,
            module_name: 'Cases',
            record_id: portal_id,
            des_folder_path: ""
        };

        const apiUrl = `${API_BASE_URL}/getRecord`;

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestBody),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    alert('Session expired. Redirecting to login...');
                    window.location.href = AUTH_URL;
                    return null;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const response_data = await readStream(response.body);
            // console.log('Received Record Data:', response_data);

            return response_data;
        } catch (error) {
            console.error('Fetch operation failed:', error);
            return null;
        }
    };

    const updateRecord = async (update_list, module_name = 'CC_Portal_Items') => {
        // console.log('Update List:', update_list);
        const randomCode = generateRandomCode(40);
        const apiUrl = `${API_BASE_URL}/updateRecord`;
        const requestData = {
            api_name: 'updateRecord',
            batch_id: randomCode,
            module_name: module_name,
            update_records_list: update_list,
        };
        // console.log('Update Request Data:', requestData);

        try {
            const response = await fetch(apiUrl, {
                method: 'POST',
                credentials: 'include', // Include cookies in the request
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(requestData),
            });

            if (!response.ok) {
                if (response.status === 401) {
                    alert('Session expired. Redirecting to login...');
                    window.location.href = AUTH_URL;
                    return;
                }
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const responseData = await readStream(response.body);
            // console.log('Update Response Data:', responseData);
        } catch (error) {
            console.error('There was a problem with the fetch operation:', error);
        }
    };

    // Handle logout function
    const handleLogout = () => {
        // Remove the token cookie
        Cookies.remove('token');
        // Redirect to the auth URL
        window.location.href = AUTH_URL;
    };

    if (portalVisibility === "No") {
        return <div>Portal is not available. Please contact your administrator.</div>;
    }
    
    
    if (!formKey) {
        return (
            <div id="overlay" className="overlay">
                <div className="loader">
                    <img className="loading" src="logo.png" alt="Loader Logo" />
                </div>
            </div>
        ); // Show a loading message while waiting for the API response
    }

    
    const formConfig = formConfigurations[formKey];

    if (!formConfig) {
        return <div>Please check with your administrator</div>; // Handle cases where the key doesn't match any form
    }

    return (
        <div className="App">
            <Header />
            <div className="App-content">
                <div className="App-header">
                    {formConfig.header}
                    <div className='shared-folder'><a
                        href={sharedFolder}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="folder-link"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="50"
                            height="50"
                            viewBox="0 0 24 24"
                            fill="none"
                            class="folder-icon"
                        >

                            <path
                                d="M4 4h5.2c.4 0 .8.2 1 .6l1.5 2.4h6.3c1.1 0 2 .9 2 2v8.5c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                                fill="#60a5fa"
                                stroke="#3b82f6"
                                stroke-width="1.2"
                            />

                            <path
                                d="M3.5 8.5h17c.8 0 1.4.7 1.2 1.5l-2 7.5c-.2.8-.9 1.5-1.8 1.5H5.1c-.8 0-1.5-.5-1.7-1.3L2 10c-.2-.7.4-1.5 1.2-1.5z"
                                fill="#bfdbfe"
                            />
                        </svg>
                    </a>
                        <p className='shared-folder-text'>Shared Folder</p>
                    </div>

                </div>
                <div className="App-subHeader-7">{formConfig.subHeader7}</div>
                <div className="App-subHeader">{formConfig.subHeader}</div>
                <div className="App-subHeader-2">{formConfig.subHeader2}</div>
                <div className="App-subHeader-3">{formConfig.subHeader3}</div>
                <div className="App-subHeader-4">{formConfig.subHeader4}</div>
                <div className="App-subHeader-5">{formConfig.subHeader5}</div>
                <div className="App-subHeader-6">{formConfig.subHeader6}</div>
                <div className="scrollable-content">
                    <FormGenerator forms={{ [formKey]: formConfig, "typeOfApplication": applicationDoc }} />
                </div>
            </div>
        </div>
    );
}

function generateRandomCode(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

// Export the App component wrapped inside a Router
export default function AppWithRouter() {
    return (
        <Router>
            <App />
        </Router>
    );
}